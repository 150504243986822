import React from 'react';


export function SideBarDivider(props: {hasBorder: boolean, title: string}) {

    return (
        <div className="sideBarDivider" >
            <div className="dividerTitle">{props.title}</div>
            {props.hasBorder && <div className="dividerBorder" />}
        </div>
    );
}