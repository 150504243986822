import React from 'react';
import {revokeSession, getDevice} from "./functions";
import Cookies from "universal-cookie";


export function Session(props: any) {
    const session = props.session
    const cookies = new Cookies()
    let strftime = require('strftime');

    const handleRevokeSessions = async () => {
        revokeSession(cookies.get("token"), session.id).then((r: any) => {
            console.log(r);
            props.forceUpdate(true);
        });
    }

    const dateFormat = "%a, %d.%m.%y, %H:%M"
    const lastSeenDate = new Date(session.last_seen * 1000);
    const lastSeenDateStr = strftime(dateFormat, lastSeenDate)

    const createdAtDate = new Date(session.created_at * 1000);
    const createdAtDateStr = strftime(dateFormat, createdAtDate)

    const deviceData = getDevice(session.user_agent)

    return (
        <>
            <div className="sessionItem">
                <div className="sessionItemContainer">
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">Session ID</div>
                        <div className="sessionEntryValue">{session.id}</div>
                    </div>
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">IP Address</div>
                        <div className="sessionEntryValue">{session.ip_address}</div>
                    </div>
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">Location</div>
                        <div className="sessionEntryValue">{session.location}</div>
                    </div>
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">Last Seen</div>
                        <div className="sessionEntryValue">{lastSeenDateStr}</div>
                    </div>
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">Created At</div>
                        <div className="sessionEntryValue">{createdAtDateStr}</div>
                    </div>
                    <div className="sessionEntry">
                        <div className="sessionEntryTitle">Device</div>
                        <div className="sessionEntryValue">
                            <deviceData.icon className="sessionEntryLogo" width={20} height={20}/>
                            {deviceData.title}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="sessionButton" onClick={handleRevokeSessions}>
                        Revoke
                    </div>
                </div>
            </div>
        </>
    );
}