import React from 'react';

import "./settings.css"
import {useNavigate} from "react-router-dom";


export function Settings() {
    const navigate = useNavigate()


    return (
        <>
            <div className="settingsContainer">
                <h1>Settings</h1>
                <div className="setting">
                    <a className="logoutButton"
                       onClick={() => navigate("/logout")}>
                        Logout
                    </a>
                </div>
            </div>
        </>
    );
}