import React from 'react';

import "./sidebar.css"

import {SideBarElement} from "./SideBarElement";
import {SideBarDivider} from "./SideBarDivider";

import {Kanban, Speedometer2, Person, Film, Trello} from 'react-bootstrap-icons';
import icons from "../../../assets/icons";
import {useLocation} from "react-router-dom";
import {useWindowDimensions} from "../../../components/utils";

function getRoute(location: string) {
    location = location.replace("dashboard/", "")
    location = location.slice(1)

    return location
}



export function Sidebar(props: {showSidebar: boolean, setShowSidebar: any, fullSize: boolean}) {

    const tab = getRoute(useLocation().pathname);
    const {width} = useWindowDimensions();

    const sideBarButtonGroups = [
        {
            title: "MAIN",
            hasBorder: true,
            elements: [
                {
                    title: "Dashboard",
                    key: "dashboard",
                    logo: <Speedometer2/>,
                    path: "",
                    active: true
                },
                {
                    title: "Projects",
                    key: "projects",
                    logo: <Kanban />,
                    path: "/projects",
                    active: false
                },
            ],
        },
        {
            title: "APPS",
            hasBorder: true,
            elements: [
                {
                    title: "Fiverr",
                    key: "fiverr",
                    logo: <icons.Fiverr width="1em" height="1em"/>,
                    path: "/fiverr",
                    active: false
                },
                {
                    title: "RapidAPI",
                    key: "rapid_api",
                    logo: <icons.RapidAPI width="1em" height="1em"/>,
                    path: "/rapid_api",
                    active: false
                },
                {
                    title: "Media",
                    key: "media",
                    logo: <Film />,
                    path: "/media",
                    active: false
                },
                {
                    title: "Trello",
                    key: "trello",
                    logo: <Trello />,
                    path: "/trello",
                    active: false
                },
            ],
        },
        {
            title: "ACCOUNT",
            hasBorder: true,
            elements: [
                {
                    title: "Account",
                    key: "account",
                    logo: <Person />,
                    path: "/account",
                    active: false
                },
            ]
        }
    ];

    function setActiveButton(tab: string) {
        sideBarButtonGroups.forEach(buttonGroup => {
            buttonGroup.elements.forEach(button => {
                button.active = button.key === tab;
            });
        })
    }
    setActiveButton(tab)
    return (
        props.showSidebar || width > 1000 ? (
            <div className={"sideBar" + (props.fullSize ? " fullSize" : "")}>
                {sideBarButtonGroups.map((buttonGroup, index) => (
                        <div key={index}>
                            <SideBarDivider hasBorder={buttonGroup.hasBorder}
                                            title={buttonGroup.title}
                            />
                            <div>
                                {buttonGroup.elements.map((button) => (
                                    <SideBarElement key={button.key}
                                                    title={button.title}
                                                    logo={button.logo}
                                                    active={button.active}
                                                    path={button.path}
                                                    setShowSidebar={props.setShowSidebar}
                                    />
                                ))}
                            </div>
                        </div>
                    ))
                }
            </div>
            ) : null

    );
}