import React from 'react';


export function DProjects() {

    return (
        <>
            <div className="dashBoardContent">
                <h1>Projects</h1>
            </div>
        </>
    );
}