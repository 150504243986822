import React from 'react';
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";

import "../pages.css"
import "./form.css"
import "./form-effects.css"

import {loginSession, registerUser} from "./functions";


export function Signup() {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordVerify, setPasswordVerify] = React.useState("");
    const [inviteCode, setInviteCode] = React.useState("");

    const cookies = new Cookies();
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // check if passwords match
        if (password !== passwordVerify) {
            setPassword("")
            setPasswordVerify("")
            alert("Passwords do not match")
            return
        }

        const response = await registerUser({
            username,
            password
        }, inviteCode);
        console.log(response)

        if (response.success) {
            const loginResponse = await loginSession(username, password, cookies);
            console.log(loginResponse)
            if (loginResponse) {
                navigate("/")
            }
        }
        else {
            console.log(response)
            alert(response.message)
        }
    }

    return (
        <>
            <div className="content">
                <h1 className="title">Register</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-input">
                        <input type="username"
                               placeholder="Username"
                               onChange={e => {setUsername(e.target.value)}}
                        />
                    </div>
                    <div className="form-input">
                        <input type="password"
                               placeholder="Password"
                               onChange={e => {setPassword(e.target.value)}}
                        />
                    </div>
                    <div className="form-input">
                        <input type="password"
                               placeholder="Verify Password"
                               onChange={e => {setPasswordVerify(e.target.value)}}
                        />
                    </div>
                    <div className="form-input">
                        <input type="text"
                               placeholder="Invite Code"
                               onChange={e => {setInviteCode(e.target.value)}}
                        />
                    </div>
                    <div className="">
                        <button type="submit" className="form-button gradient">
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}