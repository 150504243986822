import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSafeNavigate} from "../../../components/utils";


export function SideBarElement(props:
                                   { title: string,
                                       active: boolean,
                                       path: string,
                                       logo: any, setShowSidebar: any }) {
    const className = props.active ? "activeBorder active" : "activeBorder";

    const safe_navigate = useSafeNavigate(useLocation(), useNavigate());

    return (
        <div className="sideBarElement" onClick={() => {
            props.setShowSidebar(false);
            safe_navigate("/dashboard" + props.path);}}>
            <div className={className} />
            {props.logo}
            <span className="sideBarElementTitle">
                {props.title}
            </span>
        </div>
    );
}