import React from 'react';


export function Trello() {

    return (
        <>
            <div className="dashBoardContent">
                <h1>Trello</h1>
            </div>
        </>
    );
}