import React, {useEffect} from 'react';
import Cookies from "universal-cookie";

import "./fiverr.css"

import {getFinances} from "./functions";
import {FiverrFinances} from "./interfaces";

import {FiverrStats} from "./FiverrStats";
import {FiverrTransactions} from "./FiverrTransactions";


export function DFiverr() {
    const [finances, setFinances] = React.useState<FiverrFinances>({} as FiverrFinances);
    const [loading, setLoading] = React.useState<boolean>(true);

    const cookies = new Cookies()

    useEffect(() => {
        console.log("Getting finances...")
        getFinances(cookies.get("token")).then((r: any) => {
            if (r !== false) {
                console.log(r)
                setFinances(r)
                setLoading(false)
            }
            else {
                console.log(r)
                console.log("Failed to get finances")
            }
        })
    }, []);

    return (
        <>
            <div className="dashBoardContent">
                <FiverrStats finances={finances} loading={loading} />
                <FiverrTransactions transactions={finances.transactions} loading={loading} />
            </div>
        </>
    );
}