import React, {useEffect} from 'react';
import './App.css';

import {Routes, Route, useNavigate} from 'react-router-dom';

import {Home, Login, Logout, Signup, Projects, Dashboard} from "./pages";
import {Header} from "./components";
import Cookies from "universal-cookie";
import {requestToken} from "./pages/account/functions";
import {verifyUser} from "./pages/account/functions";

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
} else {
    document.documentElement.classList.remove('dark')
}

// Whenever the user explicitly chooses light mode
localStorage.theme = 'light'

// Whenever the user explicitly chooses dark mode
localStorage.theme = 'dark'

// Whenever the user explicitly chooses to respect the OS preference
localStorage.removeItem('theme')

function App() {
    const cookies = new Cookies();

    const navigate = useNavigate();
    const session = cookies.get('session', undefined)
    const token = cookies.get('token', undefined)

    const getNewToken = async () => {
        console.log("Getting new token...")
        const response = await requestToken(session);
        if (response.success) {
            cookies.set('token', response.token, {path: '/', sameSite: "strict", maxAge: 3000});
            navigate("/")
        }
        else {
            console.log(response)
            console.log("Incorrect Username or Password")
        }
    }

    useEffect(() => {
        if (!!session && !token) {
            getNewToken().then(r => console.log(r))
        }
        if (!!session && !!token) {

            verifyUser(cookies).then(r => {
                if (!r) {
                    getNewToken().then(r => console.log(r))
                }
            });
        }
    }, []);


    return (
    <>
        <Routes>
            <Route path="/dashboard/*" element={
                <>
                    <Dashboard />
                </>} />
            <Route path="/" element={
                <>
                    <Header isLoggedIn={!!session} isDashboard={false}/>
                    <Home />
                </>} />
            <Route path="/login" element={
                <>
                <Header isLoggedIn={!!session} isDashboard={false}/>
                <Login />
            </>} />
            <Route path="/logout" element={
                <>
                    <Header isLoggedIn={!!session} isDashboard={false}/>
                    <Logout />
                </>} />
            <Route path="/signup" element={
                <>
                    <Header isLoggedIn={!!session} isDashboard={false}/>
                    <Signup />
                </>} />
            <Route path="/projects" element={
                <>
                    <Header isLoggedIn={!!session} isDashboard={false}/>
                    <Projects />
                </>} />

            <Route path="*" element={
                <>
                    <Header isLoggedIn={!!session} isDashboard={false}/>
                    <h1>Not Found</h1>
                </>} />
        </Routes>
    </>
    );
}

export default App;
