import React from 'react';

import {Project} from "../components";

export function Projects() {
    return (
        <>
            <div className="content">
                <h1 className="title">Projects</h1>
                <div className="projectList">
                    <Project />
                </div>
            </div>
        </>
    );
}