import React from 'react';

import './header.css';
import {HeaderPopOver} from "./headerPopOver";
import {CaretDown, CaretUp} from "react-bootstrap-icons";
import {useWindowDimensions} from "../utils";

export function Header(props: { isLoggedIn: boolean, isDashboard: boolean, sideBarOpen?: boolean, setSideBarOpen?: any }) {
    const {width} = useWindowDimensions();

    return (
        <div className="headerContainer">
            {props.isDashboard && width < 1000 ? (
                props.sideBarOpen ? (
                    <a className="dashboardHeader" onClick={() => props.setSideBarOpen(false)}>
                        <CaretUp width="30px" height="30px"/>
                    </a>
                    ) : (
                    <a className="dashboardHeader" onClick={() => props.setSideBarOpen(true)}>
                        <CaretDown width="30px" height="30px"/>
                    </a>
                )
            ) : null}
            <div className="logo">
                SMTT
            </div>
            {props.isLoggedIn ? (
                    <HeaderPopOver links={[
                        {name: "Home", link: "/"},
                        {name: "Projects", link: "/projects"},
                        {name: "Dashboard", link: "/dashboard"},
                    ]}/>
                ) :
                (
                    <HeaderPopOver links={[
                        {name: "Home", link: "/"},
                        {name: "Projects", link: "/projects"},
                        {name: "Login", link: "/login"},
                        {name: "Signup", link: "/signup"},
                    ]}/>
                )}
        </div>
    );
}