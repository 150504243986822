import {ReactComponent as Fiverr} from "./icons/fiverr.svg";
import {ReactComponent as RapidAPI} from "./icons/rapidapi.svg";
import {ReactComponent as Postman} from "./icons/postman.svg";

const icons = {
    Fiverr,
    RapidAPI,
    Postman
}

export default icons;