import React from 'react';

import './project.css';

export function Project() {
    return (
        <div className="project">
            <div className="projectTitle">
                Project Title
            </div>
            <div className="projectDescription">
                Project Description
            </div>
        </div>
    );
}