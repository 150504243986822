import React from 'react';
import Cookies from "universal-cookie";

import "./media.css";

import {IVideo} from "./interfaces";
import {FadeLoader} from "react-spinners";
import {Video} from "./Video";


export function DMedia() {
    const [videos, setVideos] = React.useState<IVideo[]>([])
    const [loading, setLoading] = React.useState<boolean>(true);

    const cookies = new Cookies()

    return (
        <>
            <div className="dashBoardContent">
                <h1>Media</h1>
                <div className="mediaContainer">
                    {loading ? (
                        <div className="center">
                            <FadeLoader color="var(--primary)" />
                        </div>) : videos.map((v: IVideo, index) => (
                            <Video video={v} key={index}/>
                        )
                    )}
                </div>
            </div>
        </>
    );
}