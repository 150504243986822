import React, {useEffect} from 'react';

import {Routes, Route, useNavigate} from "react-router-dom";

import "../pages.css"
import "./dashboard.css"

import {Sidebar} from "./sidebar";
import {DDashboard, DFiverr, DAccount, DProjects, DRapidAPI, DMedia} from "./pages";

import {verifyUser} from "../account/functions";
import Cookies from "universal-cookie";
import {Header} from "../../components";
import {useWindowDimensions} from "../../components/utils";
import {Trello} from "./pages/trello";


export function Dashboard() {
    const {width} = useWindowDimensions();
    const [sideBarOpen, setSideBarOpen] = React.useState(width > 1000);

    const cookies = new Cookies()
    const session = cookies.get('session', undefined)
    const navigate = useNavigate()

    useEffect(() => {
        console.log("Verifying user...")
        verifyUser(cookies).then(r => {
            console.log(r)
            if (!r) {
                navigate("/login")
            }
        })
    }, [])




    return (
        <>
            <Header isLoggedIn={!!session} isDashboard={true} sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen}/>
            <div className={"dashBoardContainer" + (sideBarOpen && width < 500 ? " fullSideBar" : "")}>
                <Sidebar showSidebar={sideBarOpen} setShowSidebar={setSideBarOpen} fullSize={sideBarOpen && width < 500}/>
                <Routes>
                    <Route path="/" element={<DDashboard />} />
                    <Route path="/projects" element={<DProjects />} />

                    <Route path="/fiverr" element={<DFiverr />} />
                    <Route path="/rapid_api" element={<DRapidAPI />} />
                    <Route path="/media" element={<DMedia />} />
                    <Route path="/trello" element={<Trello />} />

                    <Route path="/account" element={<DAccount />} />
                </Routes>
            </div>
        </>
    );
}