import React from 'react';

import {FadeLoader} from "react-spinners";
import {FiverrTransaction} from "./interfaces";


export function FiverrTransactions(props: {transactions: { clearing: FiverrTransaction[]; earnings: FiverrTransaction[]; orders: FiverrTransaction[]; total: FiverrTransaction[]; withdrawals: FiverrTransaction[]; },
    loading: boolean}) {
    const {transactions, loading} = props

    return (
        <>
            <div className="transactionsContainer">
                <h1>Transactions</h1>
                {loading ? (
                    <div className="center">
                        <FadeLoader color="var(--primary)" />
                    </div>) : (
                    <div className="transactions">
                        <div>
                            <h2>Clearing</h2>
                            {transactions.clearing.map((transaction: any, index) => {
                                //const date = new Date(Date.parse(transaction.date));
                                const clearance_date = new Date(Date.parse(transaction.clearance_date));
                                const days_left = Math.round((clearance_date.getTime() - Date.now()) / (1000 * 3600 * 24))

                                return (
                                    <div className="transaction" key={index}>
                                        <span className="transactionTitle">
                                            {transaction.from}
                                            <span className="bigNote">
                                                {days_left} days left
                                            </span>
                                        </span>
                                        <span>
                                            {transaction.amount.usd + "$"}
                                        </span>
                                    </div>
                                )})}
                        </div>
                        <div>
                            <h2>Withdrawals</h2>
                            {transactions.withdrawals.map((transaction: any, index) => {

                                const date = new Date(Date.parse(transaction.date));

                                return (
                                    <div className="transaction" key={index}>
                                        <span className="transactionTitle">
                                            {transaction.from}
                                            <span className="transactionDate">
                                                {date.toLocaleDateString()}
                                            </span>
                                        </span>
                                        <span>
                                            {transaction.currency === "EUR" ? transaction.amount.eur + "€" : transaction.amount.usd + "$"}
                                        </span>
                                    </div>
                                )})}
                        </div>
                        <div>
                            <h2>Earnings</h2>
                            {transactions.earnings.map((transaction: any, index) => {

                                const date = new Date(Date.parse(transaction.date));

                                return (
                                    <div className="transaction" key={index}>
                                        <span className="transactionTitle">
                                            {transaction.from}
                                            <span className="transactionDate">
                                                {date.toLocaleDateString()}
                                            </span>
                                        </span>
                                        <span>
                                            {transaction.amount.usd + "$"}
                                        </span>
                                    </div>
                                )})}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}