import React from 'react';

import {Sessions} from "./session";
import "./account.css"
import {Settings} from "./settings";


export function DAccount() {

    return (
        <>
            <div className="dashBoardContent">
                <Settings />
                <Sessions />
            </div>
        </>
    );
}