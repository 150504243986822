import React from 'react';


export function DRapidAPI() {

    return (
        <>
            <div className="dashBoardContent">
                <h1>RapidAPI</h1>
            </div>
        </>
    );
}