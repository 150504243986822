import React, {Fragment} from 'react';
import { Popover, Transition } from '@headlessui/react'
import {useNavigate} from "react-router-dom";
import {List} from "react-bootstrap-icons";

export function HeaderPopOver( props: { links: { name: string, link: string }[] } ) {
    const navigate = useNavigate();

    return (
        <>
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`${open ? '' : 'text-opacity-90'}
                            popOverButton`}
                        >
                            <List width="40px" height="40px"/>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="">
                                <div className="popOverContainer">
                                    {props.links.map((link, index) => {
                                        return (
                                            <div key={index} className="popOverItem" onClick={() => {navigate(link.link);}}>
                                                {link.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </>
    );
}