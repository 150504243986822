import {Android2, Apple, BrowserChrome, Windows} from "react-bootstrap-icons";
import icons from "../../../../../../assets/icons";

function getDeviceIcon(device: string) {
    switch (device) {
        case "Windows":
            return Windows;
        case "Android":
            return Android2;
        case "iPhone":
            return Apple;
        case "iPad":
            return Apple;
        case "iPod":
            return Apple;
        case "IEMobile":
            return Windows;
        case "WPDesktop":
            return Windows;
        case "Postman":
            return icons.Postman;
        default:
            return BrowserChrome;
    }
}

export function getDevice(userAgent: string) {

    // get device from user agent
    const deviceMatch = userAgent.match(
        /Firefox|Windows|Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Postman/i
    );

    // return device
    if (deviceMatch) return {
        title: deviceMatch[0],
        icon: getDeviceIcon(deviceMatch[0])
    };
    // return default
    return {
        title: "Unknown",
        icon: getDeviceIcon("Unknown")
    };
}

