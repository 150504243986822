import React, {useEffect} from 'react';
import {getSessions} from "./functions";
import Cookies from "universal-cookie";
import {ISession} from "./interfaces";
import {Session} from "./Session";
import {FadeLoader} from "react-spinners";

import "./sessions.css"


export function Sessions() {
    const cookies = new Cookies()
    const [sessions, setSessions] = React.useState<ISession[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [forceUpdate, setForceUpdate] = React.useState<boolean>(false);

    useEffect(() => {
        console.log("Getting sessions...")
        getSessions(cookies.get("token")).then((r: any) => {
            if (r !== undefined) {
                console.log(r)
                setSessions(r)
                setLoading(false)
            }
            else {
                console.log(r)
                console.log("Failed to get sessions")
            }
            setForceUpdate(false);
        })
    }, [forceUpdate]);

    return (
        <>
            <div className="sessionContainer">
                <h1>Sessions</h1>

                    {loading ? (
                        <div className="center">
                            <FadeLoader color="var(--primary)" />
                        </div>) : (
                        <div className="sessionList">
                            {sessions.map((session, index) => {
                                return <Session key={index} session={session} forceUpdate={setForceUpdate}/>})}
                        </div>
                    )}
            </div>
        </>
    );
}