export function revokeSession(token: string, sessionId: number): any {

    if (!token) {
        return false;
    }

    //const base_url = "http://127.0.0.1:5000"
    const base_url = "https://oauth.smtt.me"

    // make post request
    return fetch(base_url + '/session/delete/' + sessionId, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
    ).then(r => {
        console.log(r);
        return r.json();
    }).then((r) => {
        console.log(r);
        return r.success;
    })
}