import {requestToken} from "./requestToken";

export async function loginSession(username: string, password: string, cookies: any) {

    // make post request
    return fetch('https://oauth.smtt.me/session/login', {
            method: 'POST',
            body: new URLSearchParams({
                'username': username,
                'password': password,
            }),
        }
    ).then(r => r.json()).then(r => {
        if (r.success) {
            cookies.set('session', r.token, {path: '/', sameSite: "strict", maxAge: 2592000});

            requestToken(r.token).then(r => {
                if (r.success) {
                    cookies.set('token', r.token, {path: '/', sameSite: "strict", maxAge: 3000});
                }
            })
            return true
        }
        return false
    })
}