import React from 'react';
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";

import "../pages.css"
import "./form.css"
import "./form-effects.css"

import {loginSession} from "./functions";


export function Login() {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const cookies = new Cookies();
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const response = await loginSession(username, password, cookies);
        if (response) {
            navigate("/")
        }
        else {
            alert("Incorrect Username or Password")
        }
    }

    return (
        <>
            <div className="content">
                <h1 className="title">Login</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-input">
                        <input type="text"
                               placeholder="Username"
                               onChange={e => {setUsername(e.target.value)}}
                        />
                    </div>
                    <div className="form-input">
                        <input type="password"
                               placeholder="Password"
                               onChange={e => {setPassword(e.target.value)}}
                        />
                    </div>
                    <div className="">
                        <button type="submit" className="form-button gradient spin">
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}