import React from 'react';

import {FadeLoader} from "react-spinners";
import {FiverrFinances} from "./interfaces";


export function FiverrStats(props: {finances: FiverrFinances, loading: boolean}) {
    const {finances, loading} = props

    return (
        <>
            <div className="statsContainer">
                <h1>Stats</h1>
                {loading ? (
                    <div className="center">
                        <FadeLoader color="var(--primary)" />
                    </div>) : (
                    <div className="stats">
                        <div className="stat">
                            <span className="statTitle">
                                Balance
                                <span className="statNote">
                                    {finances.count?.transactions}
                                </span>
                            </span>
                            <span>
                                {finances.stats?.amount.balance.usd + "$"}
                                <span className="statDivider"></span>
                                {finances.stats?.amount.balance.eur + "€"}
                            </span>
                        </div>
                        <div className="stat">
                            <span className="statTitle">
                                Earnings
                                <span className="statNote">
                                    {finances.count?.earnings}
                                </span>
                            </span>
                            {finances.stats?.amount.earnings + "$"}
                        </div>
                        <div className="stat">
                            <span className="statTitle">
                                Clearing
                                <span className="statNote">
                                    {finances.count?.clearing}
                                </span>
                            </span>
                            {finances.stats?.amount.clearing + "$"}
                        </div>

                        <div className="stat">
                            <span className="statTitle">
                                Withdrawals
                                <span className="statNote">
                                    {finances.count?.withdrawals}
                                </span>
                            </span>
                            <span>
                                {finances.stats?.amount.withdrawals.total_eur + "€"}
                                <span className="statDivider"></span>
                                {finances.stats?.amount.withdrawals.total_usd + "$"}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}