import {ISession} from "../interfaces";

export function getSessions(token: string): any {

    if (!token) {
        return false;
    }

    //const base_url = "http://127.0.0.1:5000"
    const base_url = "https://oauth.smtt.me"

    // make post request
    return fetch(base_url + '/session/get', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
    ).then(r => {
        return r.json();
    }).then((r) => {
        const sessions: ISession[] = r.sessions
        return sessions
    })
}