export async function verifyUser(cookies: any) {

    const token = cookies.get('token');

    if (!token) {
        return false;
    }

    // make post request
    return fetch('https://oauth.smtt.me/oauth/verify', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
    ).then(r => {
        return r.json();
    }).then(r => {
        return r.success;
    })
}