import React, {useEffect} from 'react';
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";

import "../pages.css"
import {logoutSession, logoutToken} from "./functions";


function logout(session: string, token: string):any {
    if (session !== undefined) {
        logoutSession(session).then(r => {
            console.log(r)
        });
    }
    if (token !== undefined) {
        logoutToken(token).then(r => {
            console.log(r)
        });
    }
}


export function Logout() {

    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(() => {
        logout(cookies.get('session'), cookies.get('token'))
        cookies.remove('session', {path: '/', sameSite: 'strict'})
        cookies.remove('token', {path: '/', sameSite: 'strict'})
        navigate("/")
    }, []);

    return (
        <>
            <div className="content">
                <h1 className="title">Logging out...</h1>
            </div>
        </>
    );
}