import React from 'react';

import {IVideo} from "./interfaces";


export function Video(props: {video: IVideo}) {

    const video = props.video
    let date = new Date(0);
    date.setSeconds(video.duration); // specify value for SECONDS here

    let timeString = date.toISOString().substring(11, 19);
    timeString = timeString.replace(/^0+:/, '');
    timeString = timeString.replace(/^0+/, '0');

    let viewsString;
    if (video.views !== null && video.views > 10000) {
        viewsString = Math.round(video.views / 1000) + "k"
    }
    else if (video.views !== null && video.views > 1000000) {
        viewsString = Math.round(video.views / 1000000) + "m"
    }
    else {
        viewsString = video.views + ""
    }

    return (
        <>
            <div className="mediaItem">

                <div className="mediaItemMedia">
                    <span className="mediaDuration">
                        {timeString}
                    </span>
                    <img src={video.thumbnail.image}
                         className="mediaThumbnail"
                         alt={""}
                    />
                    <video src={video.thumbnail.video}
                           autoPlay={true}
                           loop={true}
                           muted={true}
                           poster={video.thumbnail.image}
                           className="mediaThumbnailVideo"
                    />
                </div>
                <div className="mediaInfoContainer">
                    <span className="mediaTitle">
                        {video.title.slice(0, 40)}{video.title.length > 40 ? "..." : null}
                    </span>
                    <div className="mediaStats">
                        <span className="mediaViews">
                            {video.views ? (viewsString + " views") : "-"}
                        </span>

                    </div>
                </div>
            </div>
        </>
    );
}